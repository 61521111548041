html, body {
  width: 100%;
  height: 100%;
}
.player-wrapper {
  display: "flex" important;
  justify-content: "center";
   padding-top: "10px" 
}

@media only screen and (max-width: 600px) {
  .react-player {
    height:230px;
  }
}